import { manFaceWashDetail, manSkincareDetail, manStylingDetail } from "./man";
import {
	allInOneDetail,
	baseMakeDetail,
	bbCreamDetail,
	cheekDetail,
	cleansingDetail,
	concealerDetail,
	creamDetail,
	emulsionDetail,
	essenceDetail,
	eyeShadowDetail,
	eyebrowDetail,
	eyelinerDetail,
	facePowderDetail,
	faceWashDetail,
	lipstickDetail,
	liquidFoundationDetail,
	mascaraDetail,
	packMaskDetail,
	powderFoundationDetail,
	shampooConditionerDetail,
	sheetMaskDetail,
	sunCareDetail,
	tonerDetail,
} from "./woman";

export const manCategoryType = {
	manFaceWash: "【男性】洗顔料",
	manSkincare: "【男性】スキンケア",
	manStyling: "【男性】スタイリング",
} as const;

export const womanCategoryType = {
	cleansing: "メイク落とし",
	faceWash: "洗顔料",
	toner: "化粧水",
	emulsion: "乳液",
	cream: "クリーム",
	essence: "美容液",

	allInOne: "オールインワン",
	packMask: "パックマスク",
	sheetMask: "シートマスク",

	sunCare: "サンケア",
	liquidFoundation: "リキッドファンデーション",
	powderFoundation: "パウダーファンデーション",

	baseMake: "化粧下地",
	facePowder: "おしろい・フェイスパウダー",
	concealer: "コンシーラー",
	bbCream: "BBクリーム",
	lipstick: "口紅・リップグロス",
	mascara: "マスカラ",

	eyeShadow: "アイシャドー",
	eyeliner: "アイライナー",
	eyebrow: "アイブロー",
	cheek: "チーク",
	shampooConditioner: "シャンプー・コンディショナー",
} as const;

export const categoryType = {
	...manCategoryType,
	...womanCategoryType,
} as const;

export type CategoryType = keyof typeof categoryType;
export const categoryTypeKeys = Object.keys(categoryType) as CategoryType[];

/**
 * option選択肢はon-offをユーザが選べる必要があるため、選択肢はidで管理を行う
 */
export type CustomChoice = {
	id: number; // 仮の選択肢番号
	text: string; // テキストの内容
};

export type CustomChoiceConfig = {
	standards: CustomChoice[]; // 標準項目
	options?: CustomChoice[]; // オプション項目
	canAdd?: boolean; // 任意項目追加可能か
};

export type StandardCategoryDetail = {
	inUse: CustomChoiceConfig; // 中味詳細評価 【使用中】
	afterUse: CustomChoiceConfig; // 中味詳細評価 【使用後】

	nextMorning?: CustomChoiceConfig; // 中味詳細評価 【翌朝】
	daytimeSkin?: CustomChoiceConfig; // 中味詳細評価 【日中の肌】

	afterTwoHours?: CustomChoiceConfig; // 中味詳細評価 【2時間後】
	afterFourHours?: CustomChoiceConfig; // 中味詳細評価 【4時間後】
	afterEightHours?: CustomChoiceConfig; // 中味詳細評価 【8時間後】

	afterEat?: CustomChoiceConfig; //　中味詳細評価 【食後】
	afterClean?: CustomChoiceConfig; //　中味詳細評価 【洗浄後】
};

export type ShampooConditionerDateail = {
	inUseShampoo: CustomChoiceConfig; // SP 中味詳細評価 【使用中】
	inUseConditioner: CustomChoiceConfig; // CD 中味詳細評価 【使用中】
	afterTowelDry: CustomChoiceConfig; // 中味詳細評価 【タオルドライ後】
	afterDry: CustomChoiceConfig; // 中味詳細評価 【髪が乾いた状態】
	nextDay: CustomChoiceConfig; // 中味詳細評価 【翌日】
};

export type CategoryDetail = StandardCategoryDetail | ShampooConditionerDateail;

export type CategoryCustomKey = keyof StandardCategoryDetail | keyof ShampooConditionerDateail;

export type CategoryCustomConfigValues = {
	options?: number[]; // オプション選択肢のチェック
	additionalChoices?: string[]; // 追加選択肢
};
export type CategoryCustomConfig = {
	[key in CategoryCustomKey]?: CategoryCustomConfigValues;
};

/**
 * カテゴリタイプからカテゴリ情報の算出
 * @param {CategoryType} categoryType カテゴリタイプ
 * @returns {CategoryDetail} カテゴリ選択肢詳細
 */
export const getCategoryDetail = (categoryType: CategoryType): CategoryDetail => {
	switch (categoryType) {
		case "manSkincare":
			return manSkincareDetail;
		case "manFaceWash":
			return manFaceWashDetail;
		case "manStyling":
			return manStylingDetail;
		case "allInOne":
			return allInOneDetail;
		case "baseMake":
			return baseMakeDetail;
		case "bbCream":
			return bbCreamDetail;
		case "cheek":
			return cheekDetail;
		case "cleansing":
			return cleansingDetail;
		case "concealer":
			return concealerDetail;
		case "cream":
			return creamDetail;
		case "emulsion":
			return emulsionDetail;
		case "essence":
			return essenceDetail;
		case "eyeShadow":
			return eyeShadowDetail;
		case "eyebrow":
			return eyebrowDetail;
		case "eyeliner":
			return eyelinerDetail;
		case "facePowder":
			return facePowderDetail;
		case "faceWash":
			return faceWashDetail;
		case "lipstick":
			return lipstickDetail;
		case "liquidFoundation":
			return liquidFoundationDetail;
		case "mascara":
			return mascaraDetail;
		case "packMask":
			return packMaskDetail;
		case "powderFoundation":
			return powderFoundationDetail;
		case "shampooConditioner":
			return shampooConditionerDetail;
		case "sheetMask":
			return sheetMaskDetail;
		case "sunCare":
			return sunCareDetail;
		case "toner":
			return tonerDetail;
	}
};

export const isShampooCatetory = (category: CategoryDetail): category is ShampooConditionerDateail => {
	if ("inUseShampoo" in category) return true;
	return false;
};

export const isStandardCategory = (category: CategoryDetail): category is StandardCategoryDetail => {
	if ("inUse" in category) return true;
	return false;
};

export const getCategoryLabel = (category: CategoryType): string => {
	return categoryType[category].replace("【男性】", "");
};
