import {
	CategoryType,
	ShampooConditionerDateail,
	StandardCategoryDetail,
} from "../../../../repositories/survey-brand/category";
import { manFaceWashConfig, manSkincareConfig, manStylingConfig } from "./man";
import {
	allInOneConfig,
	baseMakeConfig,
	bbCreamConfig,
	cheekConfig,
	cleansingConfig,
	concealerConfig,
	creamConfig,
	emulsionConfig,
	essenceConfig,
	eyeShadowConfig,
	eyebrowConfig,
	eyelinerConfig,
	facePowderConfig,
	faceWashConfig,
	lipstickConfig,
	liquidFoundationConfig,
	mascaraConfig,
	packMaskConfig,
	powderFoundationConfig,
	shampooConditionerConfig,
	sheetMaskConfig,
	sunCareConfig,
	tonerConfig,
} from "./woman";

/**
 * 標準設問
 */
export const defaultQuestionContent = {
	intentionToContinuePurchasing: "購入製品継続購入意向",
	reasonForIntentionToContinuePurchasing: "購入製品継続購入意向理由",
	intentionToContinuePurchasingTheBrand: "ブランド継続購入意向",
	worth: "価格に見合う価値",
	brandImage: "ブランドイメージ",

	usabilityEvaluation: "中味使用性評価",
	satisfaction: "中味総合満足度",

	// 標準項目
	fragrancePreference: "香りの好み",
	fragranceIntensity: "香りの強さ",
	containerComprehensiveEvaluation: "容器総合評価",
	containerdetailedEvaluation: "容器詳細評価",
	dissatisfactionImprovement: "容器不満点・改善点",

	// 化粧水・乳液追加設問
	orderOfUseOfLotionAndMilkyLotion: "化粧水と乳液の使用順",
	combinedEmulsionBrand: "併用乳液のブランド",
	productNameOfCombinedEmulsion: "併用乳液の商品名",
	combinedTonerBrand: "併用化粧水のブランド",
	productNameOfCombinedToner: "併用化粧水の商品名",
	// オールインワン追加設問
	combinedItems: "併用アイテム",
	// LF,PF,化粧下地,おしろいFP,BB追加設問
	combinedBaseMakeupType: "併用ベースメイク種類",
	combinedBaseMakeupBrand: "併用ベースメイクブランド",
	productNameOfCombinedBaseMakeup: "併用ベースメイク商品名",

	// 美容液追加設問
	orderOfUseOfLotionAndEmulsion: "化粧水と美容液の使用順",

	channel: "購入製品購入チャネル",
	contactExperience: "購入製品との接点・体験",
	mostContactExperience: "購入製品との接点・体験（もっとも）",
	bc: "BC接触",
	bcContent: "BC接触の内容",
	annualCosmeticsSpending: "年間化粧品支出額",
	personalAnnualIncome: "個人年収",
	householdIncome: "世帯年収",
} as const;

export type DefaultQuestionContent = keyof typeof defaultQuestionContent;

/**
 * カスタム設問（標準）
 */
export const customStandardQuestionContent: { [key in keyof Required<StandardCategoryDetail>]: string } = {
	inUse: "中味詳細評価 【使用中】",
	afterUse: "中味詳細評価 【使用後】",
	nextMorning: "中味詳細評価 【翌朝】",
	daytimeSkin: "中味詳細評価 【日中の肌】",
	afterTwoHours: "中味詳細評価 【2時間後】",
	afterFourHours: "中味詳細評価 【4時間後】",
	afterEightHours: "中味詳細評価 【4時間後、8時間後】",
	afterEat: "中味詳細評価 【食事後】",
	afterClean: "中味詳細評価 【洗浄後】",
} as const;

/**
 * カスタム設問（シャンプー）
 */
export const customShampooQuestionContent: { [key in keyof Required<ShampooConditionerDateail>]: string } = {
	inUseShampoo: "SP 中味詳細評価 【使用中】",
	inUseConditioner: "CD 中味詳細評価 【使用中】",
	afterTowelDry: "中味詳細評価 【タオルドライ後】",
	afterDry: "中味詳細評価 【髪が乾いた状態】",
	nextDay: "中味詳細評価 【翌日】",
} as const;

/**
 * カスタム設問
 */
export const customContent = {
	...customStandardQuestionContent,
	...customShampooQuestionContent,
} as const;

/**
 * カスタム設問糧定義
 */
export type CustomContent = keyof typeof customContent;
export type CustomStandardQuestionContent = keyof typeof customStandardQuestionContent;
export type CustomShampooQuestionContent = keyof typeof customShampooQuestionContent;

/**
 * 設問内容
 */
export const questionContent = {
	...defaultQuestionContent,
	...customContent,
} as const;

export type QuestionContent = keyof typeof questionContent;

export const isDefaultContent = (content: QuestionContent): content is DefaultQuestionContent => {
	if (content in defaultQuestionContent) return true;
	return false;
};
/**
 * シャンプーの設問内容か
 * @param {QuestionContent} content
 * @returns {content is CustomShampooQuestionContent}
 */
export const isShampooContent = (content: QuestionContent): content is CustomShampooQuestionContent => {
	if (content in customShampooQuestionContent) return true;
	return false;
};

/**
 * 標準の設問内容か
 * @param {QuestionContent} content
 * @returns {content is CustomStandardQuestionContent}
 */
export const isStandardContent = (content: QuestionContent): content is CustomStandardQuestionContent => {
	if (content in customStandardQuestionContent) return true;
	return false;
};

/**
 * 設問設定
 * - content: 設問内容
 * - queNumber: 設問番号
 */
export type QuestionConfig = {
	content: QuestionContent;
	queNumber: number;
};

/**
 * 設問情報の取得
 * @param {CategoryType} category
 * @returns {QuestionConfig[]}
 */
export const getQuestionConfig = (category: CategoryType): QuestionConfig[] => {
	switch (category) {
		case "manSkincare":
			return manSkincareConfig;
		case "manFaceWash":
			return manFaceWashConfig;
		case "manStyling":
			return manStylingConfig;
		case "allInOne":
			return allInOneConfig;
		case "baseMake":
			return baseMakeConfig;
		case "bbCream":
			return bbCreamConfig;
		case "cheek":
			return cheekConfig;
		case "cleansing":
			return cleansingConfig;
		case "concealer":
			return concealerConfig;
		case "cream":
			return creamConfig;
		case "emulsion":
			return emulsionConfig;
		case "essence":
			return essenceConfig;
		case "eyeShadow":
			return eyeShadowConfig;
		case "eyebrow":
			return eyebrowConfig;
		case "eyeliner":
			return eyelinerConfig;
		case "facePowder":
			return facePowderConfig;
		case "faceWash":
			return faceWashConfig;
		case "lipstick":
			return lipstickConfig;
		case "liquidFoundation":
			return liquidFoundationConfig;
		case "mascara":
			return mascaraConfig;
		case "packMask":
			return packMaskConfig;
		case "powderFoundation":
			return powderFoundationConfig;
		case "shampooConditioner":
			return shampooConditionerConfig;
		case "sheetMask":
			return sheetMaskConfig;
		case "sunCare":
			return sunCareConfig;
		case "toner":
			return tonerConfig;
	}
};
